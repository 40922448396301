import './App.css';
import './tailwind.css';
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Layout from './components/Layout';

// Lazy load components
const LandingPage = React.lazy(() => import('./components/landingpage'));
const Blog = React.lazy(() => import('./components/Blog'));
const BlogPost = React.lazy(() => import('./components/BlogPost'));
const NotFound = React.lazy(() => import('./components/NotFound'));
const PrivacyPolicy = React.lazy(() => import('./components/PrivacyPolicy'));
const TermsOfService = React.lazy(() => import('./components/TermsOfService'));
const Pricing = React.lazy(() => import('./components/pricing'));
const Updates = React.lazy(() => import('./components/Updates'));
const UpdateDetail = React.lazy(() => import('./components/UpdateDetail'));
const InfluencerPromo = React.lazy(() => import('./components/InfluencerPromo'));

// Loading fallback component
const LoadingFallback = () => (
  <div className="flex items-center justify-center min-h-screen">
    <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-purple-500"></div>
  </div>
);

function App() {
  return (
    <>
      <Helmet>
        <link rel="canonical" href={`https://stylegenai.com${window.location.pathname}`} />
      </Helmet>
      <Router>
        <Layout>
          <Suspense fallback={<LoadingFallback />}>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:slug" element={<BlogPost />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/updates" element={<Updates />} />
              <Route path="/updates/:slug" element={<UpdateDetail />} />
              <Route path="/influencer-promotion" element={<InfluencerPromo />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </Layout>
      </Router>
    </>
  );
}

export default App;
